import React, { useEffect, useContext } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { withPreview } from "gatsby-source-prismic";
import { Helmet } from "react-helmet";
import Media from "react-media";

// Context
import WindowWidthContext from "../components/context/window-width";
import { PageColorConsumer } from "../components/context/page-color";
import { PageTypeConsumer } from "../components/context/page-type";

// Components
import { SingleDiscourseDesktop } from "../components/discourse/single-discourse-desktop";
import { SingleDiscourseMobile } from "../components/discourse/single-discourse-mobile";

const Page = styled.div`
  & a {
    &:hover {
      font-family: "RotationLTStd-Italic";
    }
  }

  & .footnotes {
    max-width: 550px;
    margin: 15px auto 0 auto;
  }

  & .external-links {
    margin: 11px 0 0 0;
  }

  & .footnotes {
    & h3 {
      margin: 0 0 20px 0;
    }

    & .footnote-number {
      margin: 0 0 5px 0;
    }

    & .single-footnote-container {
      margin: 0 0 14px 0;
    }

    & .footnote-text {
      & p:first-of-type {
        margin-top: 0;
      }
      & p:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  & hr {
    border: 0;
    border-bottom: 1px solid #555;
    margin: 0;
  }
`;

const DiscourseContent = ({ data, setPageColor, setPageType, location }) => {
  const windowWidth = useContext(WindowWidthContext);

  console.log(data);

  useEffect(() => {
    setPageColor(`White`);
    setPageType("practice");
  }, []);

  return (
    <Page>
      <Helmet
        title={`${data.prismicDiscourse.data.title.text} – PUP ARCHITECTS`}
      />

      <Media
        queries={{ medium: "(max-width: 768px)" }}
        defaultMatches={{ medium: windowWidth === 768 }}
        render={() => <SingleDiscourseMobile data={data} location={location} />}
      />

      <Media
        queries={{ medium: "(min-width: 769px)" }}
        defaultMatches={{ medium: windowWidth === 769 }}
        render={() => (
          <SingleDiscourseDesktop data={data} location={location} />
        )}
      />
    </Page>
  );
};

const Discourse = ({ data, location }) => (
  <PageTypeConsumer>
    {({ setPageType }) => (
      <PageColorConsumer>
        {({ setPageColor }) => (
          <DiscourseContent
            setPageColor={setPageColor}
            setPageType={setPageType}
            location={location}
            data={data}
          />
        )}
      </PageColorConsumer>
    )}
  </PageTypeConsumer>
);

export const query = graphql`
  query SingleDiscourse($uid: String!) {
    prismicDiscourse(uid: { eq: $uid }) {
      tags
      uid
      _previewable
      data {
        image {
          fluid {
            srcSetWebp
            srcWebp
          }
          alt
        }
        gallery {
          images {
            fluid {
              srcSetWebp
              srcWebp
            }
            alt
          }
        }
        year(formatString: "YYYY")
        title {
          html
          text
        }
        text {
          html
        }
        source {
          html
        }
        links {
          link {
            url
          }
        }
        footnotes {
          footnote_text {
            html
          }
        }
        article_title {
          html
        }
      }
    }
    allPrismicDiscourse(sort: { fields: data___year, order: DESC }) {
      edges {
        node {
          uid
          tags
          _previewable
          data {
            image {
              fluid {
                srcSetWebp
                srcWebp
              }
              alt
            }
            year(formatString: "YYYY")
            title {
              html
            }
          }
        }
      }
    }
  }
`;

export default withPreview(Discourse);
