import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import "../styles/gallery.css";

// Components

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;
  max-height: 100vh;

  padding: 0 20px;

  & .carousel.carousel-slider,
  & .slider-wrapper,
  & .slider,
  & .slide,
  & img {
    height: 100%;
    max-height: 100vh;
  }

  & .carousel.carousel-slider .control-arrow {
    padding: 0;
    width: 50%;
  }

  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;

const ImageContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 66.667%;
  }

  & > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  & img {
    object-fit:contain;
`;

const Caption = styled.div`
  position: relative;
  width: 100%;

  margin: 10px 0 20px 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  & .counter {
    width: 40px;
  }

  & .caption-text {
    margin: 0 auto;
  }
`;

export const DiscourseGallery = ({ data, objectFit }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(null);

  useEffect(() => {
    setTotalSlides(data.length);
  }, []);

  const updateCurrentSlide = index => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  const galleryContent = data.map((content, index) => {
    return (
      <ImageContainer
        key={`project_images_${index}`}
        objectFit={objectFit ? objectFit : `cover`}
      >
        <div className="content">
          <img
            src={content.images.fluid.srcWebp}
            srcSet={content.images.fluid.srcSetWebp}
            alt={content.images.alt}
            loading="lazy"
          />
        </div>
      </ImageContainer>
    );
  });

  return (
    <GalleryContainer>
      <Carousel
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
        infiniteLoop={true}
        useKeyboardArrows={true}
        className="discourse-gallery"
        selectedItem={currentSlide}
        onChange={index => updateCurrentSlide(index)}
        autoPlay={false}
        stopOnHover={false}
        interval={8000}
      >
        {galleryContent}
      </Carousel>

      <Caption className="nhg-small">
        {galleryContent.length > 1 && (
          <span className="counter">
            {currentSlide + 1}/{totalSlides}
          </span>
        )}
        <span className="caption-text">{data[currentSlide].images.alt}</span>
        {galleryContent.length > 1 && <span className="counter"></span>}
      </Caption>
    </GalleryContainer>
  );
};
