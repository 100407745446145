import React, { useEffect } from "react";
import styled from "styled-components";

// Components
import { DiscourseGallery } from "../images/discourse-gallery";

const Grid = styled.div`
  display: block;
`;

const GridCol = styled.div`
  width: 100%;

  & .gallery-container {
    margin: 0 0 20px 0;
  }

  & .gallery-line-separator {
    margin: 0 0 20px 0;
  }

  & .text-container {
    max-width: 550px;
    margin: 0 auto;
    padding: 0 10px;

    & .article-title {
      text-transform: uppercase;
      letter-spacing: 0.02em;
    }

    & .article-text {
      margin: 0 0 34px 0;
    }
  }

  & .active-content {
    padding: 15px 0;
  }
`;

export const SingleDiscourseMobile = ({ data }) => {
  useEffect(() => {
    document.body.style.overflow = "";
  }, []);

  const filteredImages = data.prismicDiscourse.data.gallery
    .filter(image => image.images !== undefined)
    .filter(
      image => image.images.fluid !== null && image.images.fluid !== undefined
    );

  const footnotes = data.prismicDiscourse.data.footnotes
    .filter(content => content.footnote_text.html !== "")
    .map((content, index) => (
      <div key={`footnote_${index}`} className="single-footnote-container">
        <p className="nhg-small footnote-number">{index + 1}</p>
        <div
          className="footnote-text"
          dangerouslySetInnerHTML={{
            __html: content.footnote_text.html,
          }}
        />
      </div>
    ));

  const links = data.prismicDiscourse.data.links
    .filter(content => content.link.url !== "")
    .map((content, index) => (
      <div key={`link_${index}`}>
        <a href={content.link.url} target="_blank" rel="noreferrer noopener">
          {content.link.url}
        </a>
      </div>
    ));

  return (
    <>
      <Grid>
        <GridCol>
          <div className="active-content">
            {filteredImages.length >= 1 && (
              <>
                <div className="gallery-container">
                  {filteredImages.length >= 1 && (
                    <DiscourseGallery data={filteredImages} />
                  )}
                </div>
                <hr class="gallery-line-separator" />
              </>
            )}

            <div className="text-container">
              <div
                className="article-title"
                dangerouslySetInnerHTML={{
                  __html: data.prismicDiscourse.data.article_title.html,
                }}
              />
              <div
                className="article-text"
                dangerouslySetInnerHTML={{
                  __html: data.prismicDiscourse.data.text.html,
                }}
              />
            </div>

            {footnotes.length >= 1 && (
              <>
                <hr />
                <div className=" text-container">
                  <div className="footnotes">{footnotes}</div>
                </div>
              </>
            )}

            {links.length >= 1 && (
              <>
                <hr />
                <div className=" text-container">
                  <div className="external-links">{links}</div>
                </div>
              </>
            )}
          </div>
        </GridCol>
      </Grid>
    </>
  );
};
