import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

// Functions
import { dynamicSort } from "../utils/dynamic-sort";

// Components
import { DiscourseTableSort } from "../discourse/discourse-table-sort";
import { DiscourseGallery } from "../images/discourse-gallery";

const HeaderRow = styled.div`
  padding: 10px 0;

  cursor: pointer;

  & h1,
  & h2,
  & p,
  & a {
    font-family: "RotationLTStd-Roman";

    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.02em;
  }
`;

const Articles = styled.div`
  & > div:nth-of-type(${props => props.activeRowIndex}) {
    border-color: #000;
  }

  & > div:nth-of-type(${props => props.activeRowIndex + 1}) {
    color: #000;
    border-color: #000;

    & h1,
    & h2,
    & p,
    & a {
      font-family: "neue-haas-grotesk-text", sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 12px;
      line-height: 20px;

      letter-spacing: 0.02em;
      text-transform: uppercase;
    }
  }
`;

const Row = styled.div`
  border-bottom: 1px solid #e5e5e5;

  cursor: pointer;

  & a {
    display: block;
    width: 100%;
    padding: 8px 0 4px 0;
  }

  & h1,
  & h2,
  & p,
  & a {
    font-family: "RotationLTStd-Roman";

    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.02em;
  }

  &.is-active {
    & a {
      padding: 6px 0 5px 0;
    }
  }
`;

const Col = styled.div`
  & p {
    margin: 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${props => props.desktopCol};
  grid-gap: ${props => (props.desktopGridGap ? props.desktopGridGap : `0px`)};
`;

const GridCol = styled.div`
  width: 100%;
  padding: 20px 10px;

  &.scrollable {
    overflow: scroll;
    height: 100vh;
  }

  & .gallery-container {
    padding: 0 20px;
    margin: 0 0 20px 0;
  }

  & .gallery-line-separator {
    margin: 0 0 20px 0;
  }

  & .text-container {
    max-width: 550px;
    margin: 0 auto;

    & .article-title {
      text-transform: uppercase;
      letter-spacing: 0.02em;
    }

    & .article-text {
      margin: 0 0 34px 0;
    }
  }

  & .active-content {
    width: 100%;
    padding: 15px 0;
  }

  &:first-of-type {
    border-right: 1px solid #555;
  }
`;

const ProjectImage = styled.div`
  width: 100%;
  height: calc(100vh - 20px - 40px);
  padding: 20px;

  & img {
    width: 100%;
    height: 100%;

    object-fit: contain;
    object-position: center;
  }
`;

export const SingleDiscourseDesktop = ({ data, location }) => {
  const [activeImage, setActiveImage] = useState(null);

  const findActiveRowIndex =
    data.allPrismicDiscourse !== undefined
      ? data.allPrismicDiscourse.edges
          .map(article => article.node.uid)
          .indexOf(data.prismicDiscourse.uid)
      : 0;

  const [activeRow, setActiveRow] = useState(findActiveRowIndex);
  const [disableMouseEvents, setDisableMouseEvents] = useState(true);
  const [activeTableSort, setActiveTableSort] = useState(`-year`);

  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setDisableMouseEvents(false);
    }, 300);
  }, [location]);

  const discourse =
    data.allPrismicDiscourse !== undefined
      ? data.allPrismicDiscourse.edges
          .sort(dynamicSort(activeTableSort))
          .map((content, index) => (
            <Row
              key={`projects_${index}_${content.node.id}`}
              onMouseOver={() => {
                if (disableMouseEvents === false) {
                  setActiveImage(content.node.data.image);
                  setActiveRow(index);
                }
              }}
              onMouseLeave={() => {
                setActiveImage(null);
                setActiveRow(findActiveRowIndex);
              }}
              className={
                content.node.uid === data.prismicDiscourse.uid
                  ? `is-active`
                  : ``
              }
            >
              <Link to={`/discourse/${content.node.uid}`}>
                <Grid desktopCol={`1fr 90px 60px`} desktopGridGap={`20px`}>
                  <Col>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: content.node.data.title.html,
                      }}
                    />
                  </Col>
                  <Col>
                    <div>
                      <p>{content.node.tags[0]}</p>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <p>{content.node.data.year}</p>
                    </div>
                  </Col>
                </Grid>
              </Link>
            </Row>
          ))
      : null;

  const filteredImages = data.prismicDiscourse.data.gallery
    .filter(image => image.images !== undefined)
    .filter(
      image => image.images.fluid !== null && image.images.fluid !== undefined
    );

  const footnotes = data.prismicDiscourse.data.footnotes
    .filter(content => content.footnote_text.html !== "")
    .map((content, index) => (
      <div key={`footnote_${index}`} className="single-footnote-container">
        <p className="nhg-small footnote-number">{index + 1}</p>
        <div
          className="footnote-text"
          dangerouslySetInnerHTML={{
            __html: content.footnote_text.html,
          }}
        />
      </div>
    ));

  const links = data.prismicDiscourse.data.links
    .filter(content => content.link.url !== "")
    .map((content, index) => (
      <div key={`link_${index}`}>
        <a href={content.link.url} target="_blank" rel="noreferrer noopener">
          {content.link.url}
        </a>
      </div>
    ));

  return (
    <>
      <Grid desktopCol={`1fr 1fr`}>
        {activeImage !== null && (
          <GridCol>
            {activeImage !== null && (
              <ProjectImage>
                {activeImage.fluid !== null && (
                  <img
                    src={activeImage.fluid.srcWebp}
                    srcSet={activeImage.fluid.srcSetWebp}
                    alt={activeImage.alt}
                    loading="lazy"
                  />
                )}
              </ProjectImage>
            )}
          </GridCol>
        )}

        {activeImage === null && (
          <GridCol className="scrollable">
            <div className="active-content">
              {filteredImages.length >= 1 && (
                <>
                  <div className="gallery-container">
                    {filteredImages.length >= 1 && (
                      <DiscourseGallery data={filteredImages} />
                    )}
                  </div>
                  <hr className="gallery-line-separator" />
                </>
              )}

              <div className="text-container">
                <div
                  className="article-title"
                  dangerouslySetInnerHTML={{
                    __html: data.prismicDiscourse.data.article_title.html,
                  }}
                />
                <div
                  className="article-text"
                  dangerouslySetInnerHTML={{
                    __html: data.prismicDiscourse.data.text.html,
                  }}
                />
              </div>

              {footnotes.length >= 1 && (
                <>
                  <hr />
                  <div className=" text-container">
                    <div className="footnotes">{footnotes}</div>
                  </div>
                </>
              )}

              {links.length >= 1 && (
                <>
                  <hr />
                  <div className=" text-container">
                    <div className="external-links">{links}</div>
                  </div>
                </>
              )}
            </div>
          </GridCol>
        )}

        <GridCol className="scrollable">
          <HeaderRow>
            <Grid desktopCol={`1fr 90px 60px`} desktopGridGap={`20px`}>
              <DiscourseTableSort
                activeTableSort={activeTableSort}
                setActiveTableSort={setActiveTableSort}
              />
            </Grid>
          </HeaderRow>

          <Articles activeRowIndex={activeRow}>{discourse}</Articles>
        </GridCol>
      </Grid>
    </>
  );
};
